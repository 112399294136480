.ad {
  content: url('assets/ads/steeringbig.png');
  width: 100%;
}

.ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
}

.banner {
  position: absolute;
  z-index: -1;
  height: 11em;
  left: 0;
  top: 0em;;
  width: 100%;
  background-color: #1a1a1aff;
}

.content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.text {
  width: 100%;
  color: white;
  padding: 2em;
}

.header {
  font-family: Sui;
  font-style: italic;
  font-size: 2em;
}

.grid {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ctn {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bg {
  background-image: url('assets/about/sky.webp');
  background-size: cover;
  position: absolute;
  -webkit-filter: greyscale(100%);
  filter: greyscale(100%);
  width: 100%;
  height: 50%;
  bottom: 0;
}

/* .ctn, .ctn > * {
  border: 1px solid red;
} */

.mapCtn {
  height: 30rem;
  width: 100vw;
  position: relative;
  margin: 1rem;
  margin-bottom: 5rem;
  box-shadow: 0px 0px 10px 1px grey;
}

.info {
  width: fit-content;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.infoContent {
  width: 25rem;
  height: 14em;
  background-color: white;
  box-shadow: 0px 0px 10px 1px grey;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  font-size: 1rem;
  white-space: nowrap;
  margin: 0 1rem 1rem 1rem;
  padding: 0 1rem 1rem 1rem;
  padding: 1em;
  color: #5e0000;
}

.infoStatus {
  font-size: 1.3rem;
}

.infoHeader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  color: rgb(190, 0, 0);
  font-family: Blacksword;
}

.infoIcon {
  font-size: 1.8rem;
  margin-bottom: 0.5rem;
}

.map {
  width: 100%;
  height: 100%;
}

.mapHeader {
  position: absolute;
  color: white;
  text-shadow: 0px 0px 5px black;
  margin: 1rem;
  left: 0;
  top: 0;
  font-family: Louis;
  font-size: 2rem;
  z-index: var(--map-header-z-index);
}

.infoWindow {
  color: black;
  display: flex;
  flex-direction: column;
  width: 10rem;
  font-family: RobotoR;
}

.numbers {
  color: rgb(188, 0, 0);
  display: flex;
  width: 70%;
  justify-content: space-around;
  padding: 1em;
  font-family: Louis;
  margin-top: 3em;
  display: none;
}

.numberTitle {
  font-family: Blacksword;
  font-size: 2em;
  margin-right: 1em;
}

.end {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: rgb(112, 0, 0);
  padding: 1em;
  font-family: Louis;
}

.altHeader {
  font-size: 2em;
  margin-bottom: 0.25em;
}

.subheader {
  margin-bottom: 1em;
}

@media screen and (max-width: 1070px) {
  .info {
    flex-wrap: wrap;
  }

  .hours {
    order: 2;
  }
}

@media screen and (max-width: 400px) {
  .infoContent > * {
    white-space: normal;
  }
  .infoContent { 
    width: 100%;
  }
}

.ad1 {
  content: url('assets/ads/appmed.png');
  width: 100%;
}

@media screen and (max-width: 2000px) {
  .content {
    width: 80%;
  }
}

@media screen and (max-width: 1750px) {
  .content {
    width: 95%;
  }
}

@media screen and (max-width: 1500px) {
  .grid {
    width: 100%;
  }
  .infoContent {
    width: 20em;
  }
}

@media screen and (max-width: 1300px) {
  .grid {
    width: 70%;
  }

  .infoContent {
    width: 17em;
  }
}

@media screen and (max-width: 1000px) {
  .ad1 {
    content: url('assets/ads/app.png');
  }

  .text {
    width: 100%;
  }

  .banner {
    height: 13em;
  }

  .grid {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .ad1 {
    content: url('assets/ads/appsmall.png');
  }
}

@media screen and (max-width: 600px) {
  .ad1 {
    width: 140%;
  }
}

@media screen and (max-width: 400px) {
  .ad1 {
    content: url('assets/ads/appxs.png');
    width: 180%;
    margin-left: 2em;
  }
  .banner {
    height: 14em;
  }
}

@media screen and (max-width: 1500px) {
  .ad {
    content: url('assets/ads/steeringmed.png');
  }
}

@media screen and (max-width: 1000px) {
  .ad {
    content: url('assets/ads/steeringsmall.png');
  }
  .numbers {
    /* flex-direction: column; */
    align-items: center;
  }
  .number {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .numberTitle {
    width: 100%;
    text-align: center;
  }
}

@media screen and (max-width: 700px) {
  .ad {
    content: url('assets/ads/steeringxs.png');
  }
}

@media screen and (max-width: 500px) {
  .ad {
    display: none;
  }
}

