.ctn {
  color: var(--secondary-color);
  background-color: #333333ff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: color, background-color, box-shadow;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  white-space: nowrap;
  padding: 0.6em 1em;
}

.ctn:hover {
  background-image: none;
  background-color: white;
  color: darkslategrey;
  box-shadow: 0 0 10px 2px lightblue;
}