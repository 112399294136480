.ctn {
  height: 22em;
  width: 22em;
  box-shadow: 0px 0px 10px 1px grey;
  background-color: white;
  margin: 0 0.5em;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-bottom: none;
  z-index: 1;
  transition: transform 0.5s ease;
}

.ctn:hover {
  cursor: pointer;
  transform: scale(1.1);
  z-index: 2;
}

.thumbnail {
  height: 75%;
}

.footer {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: darkslategrey;
  /* font-size: 1.5em; */
}

.color {
  background-color: rgb(184, 0, 0);
  height: 0.5em;
}


@media screen and (max-width: 2150px) {
  .ctn {
    height: 18em;
    width: 20em;
  }
}

@media screen and (max-width: 1450px) {
  .ctn {
    height: 15em;
    width: 15em;
  }
}

@media screen and (max-width: 1100px) {
  .ctn {
    height: 30em;
    width: 40em;
  }
}

@media screen and (max-width: 625px) {
  .ctn {
    height: 20em;
    width: 100%;
  }
}

@media screen and (max-width: 625px) {
  .ctn {
    height: 20em;
  }
}

@media screen and (max-width: 400px) {
  .ctn {
    height: 18em;
  }
}
