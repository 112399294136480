* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: blue;
  --secondary-color: white;
  --tertiary-color: navy;

  color: black;
  font-family: RobotoR;

  --body-width: calc(100vw - (100vw - 100%));
  --nav-z-index: 30;
  --carousel-btn-z-index: 20;
  --map-header-z-index: 20;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;

}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: black;
}

body {
  background-color: white;
  position: relative;
  overflow-x: hidden;
}

@font-face {
  font-family: RobotoR;
  src: url('assets/fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: Coolvetica;
  src: url('assets/fonts/Coolvetica/coolvetica\ rg.otf')
}

@font-face {
  font-family: Louis;
  src: url('assets/fonts/Louis/Louis\ George\ Cafe.ttf');
}

@font-face {
  font-family: LouisB;
  src: url('assets/fonts/Louis/Louis\ George\ Cafe\ Bold.ttf');
}

@font-face {
  font-family: LouisL;
  src: url('assets/fonts/Louis/Louis\ George\ Cafe\ Light.ttf');
}

@font-face {
  font-family: Sui;
  src: url('assets/fonts/SUI/sui.otf');
}

@font-face {
  font-family: HighSpeed;
  src: url('assets/fonts/HighSpeed/HighSpeed.ttf');
}

