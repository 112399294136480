.vars {
  --item-width: 98vw;
  --item-height: 98vh;
}

.ctn {
  composes: vars;
  position: fixed;
  left: calc(50% - var(--item-width) / 2);
  top: calc(50% - var(--item-height) / 2);
  width: var(--item-width);
  height: var(--item-height);
  z-index: 40;
  background-color: white;
  box-shadow: 0px 0px 10px 1px black;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text {
  font-family: RobotoR;
  height: 100%;
  width: 25%;
  font-size: 1.5rem;
  color: darkslategray;
  display: flex; 
  flex-direction: column;
  border: 1px solid blue;
  overflow: auto;
  justify-content: space-between;
  padding: 1rem;
}

.title {
  font-size: 1.33em;
}

.exit {
  width: fit-content;
  text-align: end;
  padding: 0.25em;
  font-size: 2.5rem;
  position: absolute;
  left: 0;
  top: 0;
  color: white;
  background-color: black;
  border-radius: 10px;
  z-index: 50;
  box-shadow: 0px 0px 10px 1px black;
  opacity: 0.7;
  margin: 0.1em;
}

.exit:active  {
  color: white;
  background-color: black;
}

.exit > * {
  display: flex;
  align-items: center;
}

.content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.media {
  width: 75%;
  height: 100%;
}

.snippet {
  display: flex;
  align-items: center;
  color: grey;
  font-size: 1em;
}

.btn {
  font-size: 1em;
}

.snippet > * {
  margin-right: 0.25em;
  font-size: 1.67em;
}

.desc {
  flex-grow: 1;
  font-size: 1em;
}

.input {
  font-size: 1em;
  padding: 0.5em;
  width: 100%;
  outline: none;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
}

@media screen and (max-width: 2000px) {
  .exit {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1900px) {
  .text {
    font-size: 1.3rem;
  }
  /* 
    title: 2
    price: 2
    posted: 1.5
    snippet: 1.5
    logo: 2.5
  */
}

@media screen and (max-width: 1600px) {
  .text {
    font-size: 0.9rem;
  }

  .exit {
    font-size: 1.7rem;
  }
}

@media screen and (max-width: 1000px) {
  .content {
    flex-direction: column;
  }

  .content > * {
    width: 100%;
  }

  .media {
    height: 60%;
  }

  .text {
    flex-grow: 1;
    height: 0;
    border: none;
  }
}