.clouds {
  width: 100%;
  background-image: url('assets/about/sky.webp');
  background-color: black;
  background-blend-mode: luminosity;
  mask: linear-gradient(to top, white 70%, transparent);
  height: 60em;
  position: absolute;
  z-index: -1;
  bottom: 0;
  background-repeat: no-repeat;
  background-size: cover;
  pointer-events: none;
}

.ctn {
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.hr {
  width: 60%;
  margin-top: 8em;
  height: 0.1em;
  background-color: black;
  border: none;
}

.motto {
  color: white;
  font-size: 2.5em;
  font-family: HighSpeed;
  margin-top: 2em;
}

.submotto {
  color: white;
  width: 60%;
  text-align: center;
  margin-top: 1em;
  font-style: italic;
  font-size: 1.25em;
}

.content {
  height: 35em;
  width: 100%;
  margin-top: 1em;
  display: flex;
  justify-content: end;
  position: relative;
}

.front {
  height: 70em;
  position: absolute;
  right: 0;
  top: -25em;
  z-index: -1;
  filter: grayscale(100%);
  -webkit-filter: greyscale(100%);

}

.reviews {
  position: absolute;
  font-size: 1.5em;

  left: 5em;
  top: 7em;
  padding: 2em;
  height: 50%;
  width: 50%;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  color: black;
  margin-bottom: 2em;
  cursor: pointer;

}

.author, .quote {
  transition: opacity 0.5s ease;
  opacity: 0;
}

.appear {
  opacity: 1 !important;
}

.text {
  z-index: 1;
  font-family: Louis;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  margin-bottom: 2em;
  
}

.quote {
  width: 80%;
  white-space: normal;
  display: flex;
  justify-content: center;
  align-items: center;
}

.marksLeft {
  font-size: 3em;
  margin-top: 0em;
}

.marksRight {
  font-size: 3em;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-bottom: 0em;
}

.credit {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}

.btn {
  background-color: rgb(61, 61, 61) !important;
  color: white;
  font-size: 0.8em !important;
  width: fit-content;
}

.btn:hover {
  cursor: pointer;
  background-color: white !important;
  color: black;
}

.spheal {
  margin-top: 4em;
  border-radius: 3px;
  background-image: linear-gradient(to right, #ff0000, black);
  
  padding: 1em;
  color: white;
  text-align: center;
}

@media screen and (max-width: 2100px) {
  .ctn {
    font-size: 0.8rem;
  }
}

@media screen and (max-width: 1800px) {
  .ctn {
    font-size: 0.8rem;
  }

  .reviews {
    width: 60%;
    height: 80%;
    left: 0;
    top: 2.5em;
  }
  .credit {
    flex-direction: column;
  }
  .btn {
    margin-top: 1em;
  }
}

@media screen and (max-width: 1650px) {
  .spheal {
    opacity: 0.8;
  }
}

@media screen and (max-width: 1200px) {
  .reviews {
    left: 5%;
    width: 90%;
    color: white;
  }

  .reviews {
    background-image: radial-gradient(rgba(0, 0, 0, 0.9), transparent 70%, transparent);
  }

  .quote {
    background-color: rgba(128, 0, 0, 0.711);
    padding: 1em;

  }

  .credit { 
    align-items: center;
  }
  
}

@media screen and (max-width: 900px) {
  .motto {
    display: none;
  }

  .submotto {
    display: none;
  }

  .spheal {
    display: none;
  }
  .reviews {
    width: 100%;
    left: 0;
  }
  
  .marksLeft, .marksRight {
    display: none;
    padding: 0;
  }
  .text {
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0;
  }
  .reviews {
    padding: 0;
  }


}

@media screen and (max-width: 800px) {
  .front {
    height: 55em;
    top: -10em;
  }
}