.ctn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.banner {
  position: absolute;
  z-index: -1;
  height: 11em;
  left: 0;
  top: 0em;;
  width: 100%;
  background-color: #1a1a1aff;
}

.content {
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.text {
  width: 100%;
  color: white;
  padding: 2em;
}

.header {
  font-family: Sui;
  font-style: italic;
  font-size: 2em;
}

.grid {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ad1 {
  content: url('assets/ads/appmed.png');
  width: 100%;
}

@media screen and (max-width: 2000px) {
  .content {
    width: 80%;
  }
}

@media screen and (max-width: 1750px) {
  .content {
    width: 95%;
  }
}

@media screen and (max-width: 1500px) {
  .ad1 {
    content: url('assets/ads/appmed2.png');
  }

  .grid {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .grid {
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .ad1 {
    content: url('assets/ads/app.png');
  }

  .text {
    width: 100%;
  }

  .banner {
    height: 13em;
  }

  .grid {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  .ad1 {
    content: url('assets/ads/appsmall.png');
  }
}

@media screen and (max-width: 600px) {
  .ad1 {
    width: 140%;
  }
}

@media screen and (max-width: 400px) {
  .ad1 {
    content: url('assets/ads/appxs.png');
    width: 180%;
    margin-left: 2em;
  }
  .banner {
    height: 14em;
  }
}