.vars {
  --display-width: 100vw;
  --display-height: auto;
}

.ctn {
  composes: vars;
  font-size: 1rem;
  height: var(--display-height);
  width: var(--body-width);
}

.display {
  composes: vars;
  overflow: hidden;
  height: 100%;
  width: 100%;
  position: relative;
  background-color: rgb(255, 255, 255);
  z-index: 1;
}

.slide {
  composes: vars;
  height: auto;
  width: 100%;
  display: flex;
  flex-direction: row;
  content: url('assets/carousel/hellobig.webp');
  background-repeat: no-repeat;
  background-size: 90% auto;
  background-position: 100% 5%;
}

.slideLeft {
  transition: transform 1.5s ease;
  transform: translateX(calc( -1 * var(--display-width) ));
}

.preSlideRight {
  transform: translateX(calc( -1 * var(--display-width) ));
}

.slideRight { 
  transition: transform 1.5s ease;
  transform: translateX(0);
}

.item {
  composes: vars;
  width: var(--display-width);
  height: var(--display-height);
  background-size: cover;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1em;
  color: white;
  text-shadow: 0px 0px 5px lightblue;
}

.itemContent {
  width: 50%;
  height: fit-content;
  font-size: 2rem;
  font-family: Louis;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.itemTitle {
  font-size: 3.5rem;
  margin-bottom: 1rem;
  text-align: center;
}

.itemSubtitle {
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  text-align: center;
}

.itemSubsubtitle {
  font-size: 1.2rem;
  text-align: center;
}

.itemTitleSm {
  font-size: 3rem;
  margin-bottom: 1rem;
  text-align: center;
}

.btn {
  --btn-width: 2rem;
  --btn-height: 5rem;
  width: var(--btn-width);
  height: var(--btn-height);
  cursor: pointer;
  position: absolute;
  background-color: transparent;
  z-index: var(--carousel-btn-z-index);
  display: flex;
  justify-content: center;
  align-items: center;
  top: calc(50% - var(--btn-height) / 2);
  opacity: 0.2;
  transition: opacity 0.5s ease;
  z-index: var(--carousel-btn-z-index);
}

.btn:hover {
  opacity: 1;
}

.leftBtn {
  composes: btn;
  left: 1rem;
}

.rightBtn {
  composes: btn;
  right: 1rem;
}

@media screen and (max-width: 1300px) {
    .slide {
      content: url('assets/carousel/hellosmall.webp');
    }
}

@media screen and (max-width: 1000px) {
  .slide {
    content: url('assets/carousel/helloxs.webp');
  }
}

@media screen and (max-width: 700px) {
  .slide {
    content: url('assets/carousel/helloxxs.webp');
  }
}