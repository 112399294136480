.ctn {
  background-color: black;
  box-shadow: 0 0 10px 1px black;
  height: 20em;
  width: 100%;
  color: white;
  background-size: 35vw;
  background-repeat: no-repeat;
  background-position: 100%;
  background-image: url('/src/assets/licensed/footer.png');
  margin-top: 5em;
}

.content {
  width: 60vw;
  height: 100%;
  padding: 1.5rem 0rem 1.5rem 1.5rem;
  display: flex;
  flex-direction: row;
  line-height: 1.25rem;
}

.column {
  /* border: 1px solid red; */
  flex-basis: 33%;
  height: 100%;
  margin: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.title {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.list {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.column > * {
  white-space: nowrap;
}

@media screen and (min-width: 1500px) {
  .ctn {
    background-size: 30% auto;
    background-position: 100% 100%;
  }
}

@media screen and (max-width: 1200px) {
  .ctn {
    background-position: 100% 100%;
    background-size: auto 50%;
    height: 30em;
  }
  
  .content {
    width: 80vw;
  }
}

@media screen and (max-width: 680px) {
  .content {
    flex-direction: column;
  }

  .ctn {
    height: fit-content;
    background-position: 100% 10%;
    background-size: 65% auto;
  }

  .contact {
    order: 2;
  }

  .column {
    margin: 1em
  }
}

@media screen and (max-width: 440px) {
  .ctn {
    background-image: none;
  }
}
