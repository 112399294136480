.ctn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.innerCtn {
  width: 70%;
}

.text {
  width: 70%;
  color: white;
  padding: 2em;
}

.banner {
  position: absolute;
  z-index: -1;
  height: 11em;
  width: 100%;
  background-color: #1a1a1aff;
}

.header {
  font-family: Sui;
  font-style: italic;
  font-size: 2em;
}

.featured {
  display: flex;
  justify-content: center;
  width: 100%;
}

.grid {
  width: fit-content;
  flex-wrap: nowrap;
  width: 100% !important;
  display: flex;
  justify-content: end !important;
}

.extra {
  display: flex;
  justify-content: end;
  padding: 1em;
  margin-top: 2em;
  z-index: 500;
}

.filler {
  background-image: linear-gradient(to right, #ff0000ff, black);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center; 
  padding: 1em;
  margin-right: 1em;
  min-width: 50%;
}

.btn {
  padding: 1em;
  background-color: #1a1a1aff;
}


@media screen and (max-width: 2000px) {
  .innerCtn {
    width: 80%;
  }
}

@media screen and (max-width: 1750px) {
  .innerCtn {
    width: 95%;
  }
}

@media screen and (max-width: 1100px) {
  .grid {
    justify-content: center !important;
  }
}

@media screen and (max-width: 875px) {
  .text {
    width: 100%;
    justify-content: center;
  }
  .banner {
    height: 14em;
  }
}

@media screen and (max-width: 650px) {
  .extra {
    flex-direction: column;
  }

  .filler {
    margin-right: 0;
  }

  .btn {
    margin-top: 1em;
  }
}