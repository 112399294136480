.ctn {
  width: var(--body-width);
  height: fit-content;
  position: sticky;
  top: 0px;
  background-color: white;
  padding: 1em 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;

  transition: background-color, box-shadow;
  transition-timing-function: ease;
  transition-duration: 0.5s;
  z-index: var(--nav-z-index);
  overflow: hidden;
}

.innerCtn {
  width: 70%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.content {
  height: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  
}

.content > * {
  margin-left: 1em;
}

.item {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  flex-wrap: nowrap;
}

.icon {
  color: red;
  margin-right: 0.3em;
}

.open {
  margin: 0 0.35em 0 1em;
  display: flex;
  align-items: center;
}

.btn {
  font-size: 1em;
  padding: 0.6em 1em !important;
  box-shadow: none;
}

.logo {
  font-family: Sui;
  font-size: 1.5em;
}

@media screen and (max-width: 1400px) {
  .innerCtn {
    width: 90%;
  }
}

@media screen and (max-width: 1050px) {
  .innerCtn {
    flex-direction: column;
    width: 100%;
  }
  
  .ctn {
    flex-direction: column;
    position: relative;
  }

  .content {
    margin-top: 0.5em;
  }

  .logo {
    font-size: 2em;
  }
}

@media screen and (max-width: 700px) {
  .content {
    flex-direction: column;
  }
  .item {
    padding: 0.5em;
  }
}

@media screen and (max-width: 400px) {
  .open {
    display: none;
  }
}