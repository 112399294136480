.ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* overflow: hidden; */
}

.logo {
  width: 20em;
  position: absolute;
  left: 1em;
  top: 0px;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: grey;
}

@media screen and (max-width: 925px) {
  .logo {
    position: relative;
  }
}

@media screen and (max-width: 580px) {
  .logo {
    left: 0;
    width: 22em;
  }
}