.ctn {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  width: 20rem;
  height: 15rem;
  margin: 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: Louis;
  font-size: 2.3rem;
  color: var(--secondary-color);
  text-shadow: 2px 2px 10px black;
  transition: 
    box-shadow 0.25s ease, 
    background-color 0.25s ease, 
    transform 0.25s ease;
  background-color: rgba(0, 0, 0, 0.80);
}

.item:hover {
  box-shadow: 0px 0px 10px 1px black;
  transform: scale(1.05);
  background-blend-mode: darken;
}

.hoverItem {
  margin: 0.25rem;
  font-size: 1rem; 
}

@media screen and (min-width: 1500px) {
  .ctn {
    width: 70%;
  }
}

@media screen and (min-width: 1800px) {
  .item {
    width: 18em;
    height: 10em;
  }

  .ctn {
    width: 100%;
  }

}