.ctn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 10px 1px black;
}

.ctn > * {
  width: 100%;
  /* margin: 0.25em; */
}

.focusedImageCtn {
  composes: border from global;
  flex-grow: 1;
  padding: 0;
  overflow: hidden;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.focusedImageBg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-size: cover;
  filter: blur(30px);
  z-index: 0;
}

.focusedImage {
  width: 100%;
  height: 100%;
  position: relative;
}

.focusedImage:hover > .focusedImageBtnLeft,
.focusedImage:hover > .focusedImageBtnRight {
  opacity: 1;
  pointer-events: all;
}

.focusedImageBtnLeft, .focusedImageBtnRight {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  padding: 0.25em;
  background-color: white;
  color: darkslategrey;
  z-index: 2;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-weight: 900;
  border-radius: 5px;
  transition: transform 0.5s ease, opacity 0.5s ease;
  box-shadow: 0px 0px 10px 1px darkslategrey;
}

.focusedImageBtnLeft:hover, .focusedImageBtnRight:hover {
  cursor: pointer;
}

.focusedImageBtnLeft:active, .focusedImageBtnRight:active {
  background-color: black;
  color: white;
}

.focusedImageBtnLeft {
  left: 0.5em;
}

.focusedImageBtnRight {
  right: 0.5em;
}


.focusedImageCtn:hover .focusedOnHover {
  opacity: 0.85;
  pointer-events: all;
}

.focusedOnHover { 
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s ease;
  position: absolute;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: black;
  border-radius: 5px;
  color: white;
  user-select: none;
  font-size: 1.5rem;
  padding: 0.5em;
  z-index: 5;
  -webkit-user-select: none; /* Chrome/Safari */ 
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
}

.orderBtnCtn {
  composes: focusedOnHover;
  margin: 0 auto;
  bottom: 0;
}

.deleteImgCtn {
  composes: focusedOnHover;
  top: 0;
  right: 0;
}

.deleteImgBtn {
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 0.15em;
}

.orderBtn {
  color: white;
  background-color: black;
  font-size: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.orderBtn:active, .deleteImgBtn:active {
  color: grey;
}

.imageReel {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 1;
  margin-top: 0.5em;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.reelImage {
  min-height: 3.5em;
  min-width: 3.5em;
  height: 3.5em;
  width: 3.5em;
  box-shadow: 0px 0px 10px 1px darkslategray;
  border-radius: 10px;
  margin: 0.5em;
  overflow: hidden;
  transition: transform 0.5s ease;
  cursor: pointer;
}

.imageIdx { 
  position: absolute;
  background-color: black;
  color: white;
  padding: 0.25em;
  top: 0;
  left: 0;
}

.btn {
  background-color: var(--secondary-color);
}

.focusedReelItem {
  transform: scale(1.20);
  z-index: 2;
}

@media screen and (max-width: 2000px) {
  .focusedImageBtnLeft, .focusedImageBtnRight {
    font-size: 2rem;
  }
}

@media screen and (max-width: 1600px) {
  .focusedImageBtnLeft, .focusedImageBtnRight {
    font-size: 1.5rem;
  }

  .reelImage {
    min-height: 2em;
    min-width: 2em;
    height: 2em;
    width: 2em;
    margin: 0em 0.25em 0.5em;
  }
}

@media screen and (max-width: 800px) {
  .focusedImageBtnLeft, .focusedImageBtnRight {
    opacity: 1;
  }

  .reelImage {
    border-radius: 0px;
  }
}
